import { format } from 'date-fns'
import React from 'react'
import classNames from 'classnames'

import {
  CountryCode,
  EnergyEfficiencyRolling,
  WaterEfficiencyBenchmark,
  WaterSmallCardData,
  SiteFunction
} from '../../../../api/src/common-types'
import { ChartContainer } from '../../../components/BaseGraphs/ChartContainer'
import { lineChart } from '../../../components/BaseGraphs/GraphUtil'
import { TopBar } from '../../../components/TopBar'
import { formatAbsoluteNumber, formatRelativeNumber, multiFormatNumber } from '../../../components/Utils/format'
import {
  getWaterEfficiency,
  getWaterEfficiencyBenchmarking,
  getWaterEfficiencySmallGraphData
} from '../../../lib/APIClient'
import { useSharedSelections } from '../../../SharedSelections'

import '../KPIPage.scss'
import { getCountry, getLocationId, getLocationLabel, isSiteId } from '../../../components/Utils/utils'
import { KpiModalState, KpiPageLearnMoreModal } from '../../../components/Modal'
import { PageHeader } from '../../../components/PageHeader'
import {
  Benchmarking,
  BenchmarkingModal,
  CardRow,
  DataSourceAndModalButton,
  HeadingItem,
  KPIPerformance,
  MainCard,
  sortBenchmarks,
  Stripe
} from '../../../components/KPIPage'
import { NoDataView } from '../../../components/BaseGraphs/NoDataView'
import { useDataAvailabilityContext, useLocations, CurrentLocation } from '../../../context'
import { useDocumentTitle } from '../../../components/Utils/use-document-title'
import { GoalStatus } from '../../../components/SnapshotTopCards'
import { Route } from '../../../routes'
import { formatEnergyEfficiencySeries } from '../EnergyEfficiency/EnergyEfficiencyKPIPage'
import { Link } from '../../../components/Link'
import Button from '@ingka/button'
import { Dictionary } from 'lodash'
import _ from 'lodash'
import { WaterGraphSideBarCards } from '../../../components/GraphSideBarCards'
import ZeroGraph from '../../../components/ZeroGraph'

export const WaterEfficiencyKPIPage = () => {
  useDocumentTitle('Water Efficiency')

  const [{ func }] = useSharedSelections()
  const { dataAvailability } = useDataAvailabilityContext()
  const { currentLocation, locations } = useLocations()
  const [footprint, setFootprint] = React.useState<EnergyEfficiencyRolling[]>()
  const [dates, setDates] = React.useState<Date[]>()
  const [lastUpdated, setLastUpdated] = React.useState('')
  const [modalState, setModalState] = React.useState<KpiModalState>({ isOpen: false })
  const [rawBenchmarks, setRawBenchmarks] = React.useState<WaterEfficiencyBenchmark[]>()
  const [benchmarkModalOpen, setBenchmarkModalOpen] = React.useState(false)
  const locationId = getLocationId(currentLocation)
  const currentFY = dataAvailability?.energyCurrentFY ?? 2024
  const currentFYShort = currentFY - 2000

  const [graphData, setGraphData] = React.useState<Dictionary<WaterSmallCardData[]>>()
  const [isLoading, setIsLoading] = React.useState<boolean>(false)

  const page = Route.WaterEfficiencyKPIPage

  React.useEffect(() => {
    if (!locations || locations.length === 0) return

    setFootprint(undefined)
    setRawBenchmarks(undefined)
    setLastUpdated('')
    setIsLoading(true)

    const countryCode = isSiteId(locationId)
      ? getCountry(locationId, locations).countryCode
      : (locationId as CountryCode)

    getWaterEfficiencyBenchmarking(countryCode, func).then(setRawBenchmarks)
    getWaterEfficiency(locationId, func, currentFY).then(result => {
      setFootprint(result.data.map(f => ({ ...f, fiscalYear: f.fiscalYear - 2000 })))
      setDates(result.dates.map(d => new Date(d)))
      result.lastUpdated && setLastUpdated(format(new Date(result.lastUpdated), 'dd/MM/yyyy'))
    })
    getWaterEfficiencySmallGraphData(locationId, func).then(data => {
      setGraphData(_.groupBy(data, d => `FY${d.fiscalYear}`))
      setIsLoading(false)
    })
  }, [JSON.stringify(locations), JSON.stringify(func), JSON.stringify(currentLocation)])

  const benchmarkingHeaders = selectBenchmarkingHeaders(func, currentFYShort)
  const benchmarks = selectBenchmarks(rawBenchmarks || [], locationId, currentLocation)

  const ytdSummary = benchmarks.selectedLocation?.rolling ? (
    <span>
      {formatRelativeNumber(benchmarks.selectedLocation.currentYtd)} <span className="Label">liter/visitor YTD </span>
    </span>
  ) : (
    <span>
      0 <span className="Label">liter/visitor YTD </span>
    </span>
  )

  const exploreButton = (
    <Link page={Route.WaterExplorePage}>
      <Button text="Open Explore" type="primary" small />
    </Link>
  )

  return (
    <div className="KPIPage">
      <TopBar currentPage={Route.WaterEfficiencyKPIPage} useInFlexLayout exploreButton={exploreButton} />
      <PageHeader className="ClimateFootprintHeader" route={Route.WaterEfficiencyKPIPage} />
      <div className="PageContent">
        <Stripe title="Water Efficiency">
          <DataSourceAndModalButton
            dataSource="Sustain"
            lastUpdated={lastUpdated}
            onClick={() => setModalState({ isOpen: true, page })}
          />
        </Stripe>
        {rawBenchmarks?.length === 0 || footprint?.length === 0 ? (
          <NoDataView />
        ) : (
          <>
            <CardRow className="BenchmarkingAndGoals">
              <Benchmarking
                benchmarks={
                  benchmarks.comparison
                    ? [...(benchmarks.selectedLocation ? [benchmarks.selectedLocation] : []), ...benchmarks.comparison]
                    : []
                }
                label="litre/visitor"
                headers={benchmarkingHeaders}
                locationId={locationId}
                openModal={() => setBenchmarkModalOpen(true)}
                totalLocations={benchmarks.allLocations.length ?? 0}
              />
              <KPIPerformance
                key="goals"
                heading="KPI Performance"
                units={['YTD']}
                kpis={
                  rawBenchmarks?.length === 0 && !benchmarks.selectedLocation
                    ? []
                    : [
                        {
                          key: 'Total Visitors',
                          unit: '',
                          value: benchmarks.selectedLocation?.visitorsCurrentYtd
                            ? formatAbsoluteNumber(benchmarks.selectedLocation?.visitorsCurrentYtd)
                            : ''
                        },
                        {
                          key: 'Total Water',
                          unit: 'litres',
                          value: benchmarks.selectedLocation?.waterCurrentYtd
                            ? formatAbsoluteNumber(benchmarks.selectedLocation?.waterCurrentYtd)
                            : ''
                        },
                        {
                          key: 'Water Efficiency ',
                          unit: 'litre/visitor',
                          value: benchmarks.selectedLocation?.currentYtd
                            ? formatRelativeNumber(benchmarks.selectedLocation?.currentYtd)
                            : '',
                          keyClassNames: ['Bold'],
                          valueClassNames: ['Bold'],
                          isUnitRegular: true,
                          colorClass: benchmarks.selectedLocation?.goal
                            ? benchmarks.selectedLocation?.goal >= (benchmarks.selectedLocation?.currentYtd ?? 0)
                              ? GoalStatus.OnTrack
                              : GoalStatus.NotOnTrack
                            : ''
                        },
                        {
                          key: `FY${(dataAvailability?.waterCurrentFY || 2024) - 2000} Goal`,
                          unit: 'litre/visitor',
                          value: benchmarks.selectedLocation?.goal
                            ? formatRelativeNumber(benchmarks.selectedLocation?.goal)
                            : 'N/A',
                          isUnitRegular: true,
                          keyClassNames: ['Bold'],
                          valueClassNames: ['Bold']
                        }
                      ]
                }
              />
            </CardRow>
            <MainCard
              title="Water Efficiency"
              description="Water Efficiency = total water consumption / visitors"
              subtitle={ytdSummary}
              coWorkers
            >
              {(formatEnergyEfficiencySeries(footprint, dates) ?? []).length > 0 ? (
                <div className="GraphContainer">
                  <ChartContainer
                    domain={dates}
                    series={formatEnergyEfficiencySeries(footprint, dates, benchmarks.selectedLocation?.goal)}
                    generator={lineChart}
                    dateFormat="month"
                    lineChartConfiguration={{ focusStyle: 'none', startFromZero: false }}
                    showDecimals
                  />
                </div>
              ) : (
                dates && <ZeroGraph domain={dates} fy={currentFYShort} />
              )}
            </MainCard>
          </>
        )}
        <WaterGraphSideBarCards
          className={classNames('GraphRow', 'three-in-row')}
          graphData={graphData}
          isLoading={isLoading}
          isSite={isSiteId(locationId)}
        />
      </div>
      <KpiPageLearnMoreModal
        lastUpdated={lastUpdated}
        modalState={modalState}
        onClose={() => setModalState({ isOpen: false })}
      />
      {rawBenchmarks && rawBenchmarks.length > 0 && (
        <BenchmarkingModal
          benchmarks={benchmarks.allLocations}
          closeFn={() => setBenchmarkModalOpen(false)}
          footerBenchmark={benchmarks.country}
          headers={benchmarkingHeaders}
          isOpen={benchmarkModalOpen}
          locationId={getLocationId(currentLocation)}
          sortBy="label"
          sortDirection="asc"
          title="water efficiency litre/visitor"
        />
      )}
    </div>
  )
}

export function selectBenchmarks(
  rawBenchmarks: WaterEfficiencyBenchmark[],
  locationId: string,
  currentLocation: CurrentLocation
) {
  const countryBenchmark: WaterEfficiencyBenchmark = rawBenchmarks?.find(
    benchmark => benchmark.id === getLocationId(currentLocation)
  ) ?? {
    id: getLocationId(currentLocation),
    label: getLocationLabel(currentLocation),
    // area: 0,
    goalPrevFY: 0,
    goal: 0,
    goalNextFY: 0,
    selectable: true,
    previousYtd: 0,
    previousFy: 0,
    currentYtd: 0,
    readableDate: '',
    fiscalYear: 0,
    visitorsCurrentYtd: 0,
    waterCurrentYtd: 0,
    visitorsPreviousYtd: 0,
    waterPreviousYtd: 0,
    rolling: 0
  }
  const selectedLocationBenchmark = isSiteId(locationId)
    ? rawBenchmarks.find(b => b.id === locationId)
    : countryBenchmark
  const sortedBenchmarks = sortBenchmarks(rawBenchmarks, 'label', locationId)
  const comparisonBenchmarks = sortedBenchmarks
    .filter(b => b.id !== locationId)
    .slice(0, selectedLocationBenchmark ? 3 : 4)
  const isSite = isSiteId(locationId)
  const benchmarksWithoutCurrentLocation =
    sortedBenchmarks?.filter(b => b.id !== locationId && (isSite ? b.id?.length >= 3 : true)) ?? []

  return {
    country: countryBenchmark,
    selectedLocation: selectedLocationBenchmark,
    comparison: comparisonBenchmarks,
    allLocations: benchmarksWithoutCurrentLocation
  }
}

export function selectBenchmarkingHeaders(
  func: SiteFunction[],
  currentFYShort: number
): HeadingItem<WaterEfficiencyBenchmark>[] {
  return [
    [
      {
        name: `FY${currentFYShort - 1} YTD`,
        key: 'previousYtd',
        formatValue: n => String(multiFormatNumber(Number(n))),
        valueClassNames: 'Right'
      },
      {
        name: `FY${currentFYShort - 1} Result`,
        key: 'previousFy',
        formatValue: n => String(multiFormatNumber(Number(n))),
        valueClassNames: 'Right'
      }
    ],
    [
      {
        name: `FY${currentFYShort} YTD`,
        key: 'currentYtd',
        formatValue: n => String(multiFormatNumber(Number(n))),
        valueClassNames: benchmark =>
          classNames(
            'Right',
            'YTD',
            !benchmark.goal || !benchmark.currentYtd
              ? 'Black'
              : !benchmark.goal || benchmark.currentYtd < benchmark.goal
              ? 'OnTrack'
              : 'YTD'
          )
      },
      {
        name: 'Rolling 12 mos',
        key: 'rolling',
        formatValue: n => String(multiFormatNumber(Number(n))),
        valueClassNames: ['Right', 'Black']
      }
    ],
    [
      {
        name: `FY${currentFYShort - 1} Goal`,
        key: 'goalPrevFY',
        formatValue: (n, benchmark) =>
          !isSiteId(benchmark.id) && func.includes('ALL') ? null : String(multiFormatNumber(Number(n))),
        valueClassNames: 'Right'
      }
    ],
    [
      {
        name: `FY${currentFYShort} Goal`,
        key: 'goal',
        formatValue: (n, benchmark) =>
          !isSiteId(benchmark.id) && func.includes('ALL') ? null : String(multiFormatNumber(Number(n))),
        valueClassNames: 'Right'
      }
    ],
    [
      {
        name: `FY${currentFYShort + 1} Goal`,
        key: 'goalNextFY',
        formatValue: (n, benchmark) =>
          !isSiteId(benchmark.id) && func.includes('ALL') ? null : String(multiFormatNumber(Number(n))),
        valueClassNames: 'Right'
      }
    ]
  ]
}
