import { isSameDay } from 'date-fns'
import React, { Fragment } from 'react'
import classNames from 'classnames'

import { CountryCode, RevenueBase, RevenueBenchmark } from '../../../../api/src/common-types'
import { ChartContainer } from '../../../components/BaseGraphs/ChartContainer'
import { stackedBarChart } from '../../../components/BaseGraphs/GraphUtil'
import { TopBar } from '../../../components/TopBar'
import { formatAbsoluteNumber } from '../../../components/Utils/format'
import { getBenchmarksRevenue, getCircularRevenue, getPerformanceRevenue } from '../../../lib/APIClient'
import { useSharedSelections } from '../../../SharedSelections'

import '../KPIPage.scss'
import {
  getCountry,
  getCurrencySymbol,
  getLocationId,
  getLocationLabel,
  isCluster,
  isSiteId
} from '../../../components/Utils/utils'
import { KpiModalState, KpiPageLearnMoreModal } from '../../../components/Modal'
import { PageHeader } from '../../../components/PageHeader'
import {
  Benchmarking,
  BenchmarkingModal,
  CardRow,
  DataSourceAndModalButton,
  HeadingItem,
  KPIPerformance,
  MainCard,
  sortBenchmarks,
  Stripe
} from '../../../components/KPIPage'
import { NoDataView, NoDataViewSmall } from '../../../components/BaseGraphs/NoDataView'
import { CurrentLocation, useDataAvailabilityContext, useLocations } from '../../../context'
import { useDocumentTitle } from '../../../components/Utils/use-document-title'
import { Route } from '../../../routes'
import { flatMap, sumBy } from 'lodash'
import { SideBarCardsLoading } from '../../../components/GraphSideBarCards'
import { LoadingSkeleton } from '../../../components/LoadingSkeleton'
import colours from '../../../Colours.module.scss'
import { SumIndicator } from '../../../components/BaseGraphs/Indicators'
// import _ from 'lodash'
import './CircularRevenueKPIPage.scss'

// const filters: { label: string; disabled?: boolean }[] = [
//   { label: 'Products' },
//   { label: 'Services', disabled: true },
//   { label: 'Other', disabled: true }
// ]

export const CircularRevenueKPIPage = () => {
  useDocumentTitle('Circular Revenue')

  const [{ func }] = useSharedSelections()
  const { dataAvailability } = useDataAvailabilityContext()
  const { currentLocation, locations } = useLocations()
  // const [dates, setDates] = React.useState<Date[]>()
  const [lastUpdated, setLastUpdated] = React.useState('')
  const [modalState, setModalState] = React.useState<KpiModalState>({ isOpen: false })
  const [rawBenchmarks, setRawBenchmarks] = React.useState<RevenueBenchmark[]>()
  const [benchmarkModalOpen, setBenchmarkModalOpen] = React.useState(false)
  const locationId = getLocationId(currentLocation)
  const currentFY = dataAvailability?.revenueCurrentFY ?? 2024
  const currentFYShort = currentFY - 2000

  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  // const [selectedFilter, setSelectedFilter] = React.useState<string>(filters[0].label)
  // const filterLabels: { [key: string]: string } = {
  //   Products: 'Revenue From Circular Products',
  //   Services: 'Circular Services',
  //   Other: 'Other Income'
  // }

  const page = Route.CircularRevenueKPIPage

  const [revenue, setRevenue] = React.useState<RevenueBase[]>()
  const [revenuePerformance, setRevenuePerformance] = React.useState<RevenueBase>()
  const country = !isCluster(locationId) && locations.length > 0 ? getCountry(locationId, locations) : undefined

  const selector = {
    locationId,
    site: locationId.length > 3 ? locationId : 'ALL',
    countryCodes: locationId.length === 2 ? [locationId as CountryCode] : []
  }

  React.useEffect(() => {
    if (!locations || locations.length === 0) return

    setRawBenchmarks(undefined)
    setLastUpdated('')
    setIsLoading(true)

    getPerformanceRevenue(selector).then(({ data, lastUpdated }) => {
      setRevenuePerformance(data)
      setLastUpdated(lastUpdated)
    })

    country && getBenchmarksRevenue(country?.countryCode).then(setRawBenchmarks)
    getCircularRevenue(selector).then(data => setRevenue(data.reverse()))
    setIsLoading(false)
  }, [JSON.stringify(locations), JSON.stringify(func), JSON.stringify(currentLocation), country])

  const benchmarkHeaders = selectBenchmarkingHeaders(currentFYShort)
  const benchmarks = selectBenchmarks(rawBenchmarks || [], locationId, currentLocation)

  // const ytdSummary = benchmarks.selectedLocation?.rolling ? (
  //   <span>
  //     {benchmarks.selectedLocation.currentYtd.toFixed(1)} <span className="Label">EUR</span>
  //   </span>
  // ) : (
  //   <span>
  //     0 <span className="Label">EUR</span>
  //   </span>
  // )

  const filteredSales = revenue?.find(s => s.week === 36)
  const index = filteredSales && revenue?.indexOf(filteredSales)
  const filteredRevenueSum = revenue?.slice(index).reduce((acc, currentValue) => {
    return acc + currentValue.totalAfterSalesRevenue + currentValue.totalSecondRevenue
  }, 0)

  // const filteredRevenueSum = (_.last(revenue)?.totalSecondRevenue || 0) + (_.last(revenue)?.totalAfterSalesRevenue || 0)
  // const currencyCode = getCurrencyCode(currentLocation)
  const currencyCode = 'EUR'

  return (
    <div className="KPIPage">
      <TopBar currentPage={page} useInFlexLayout />
      <PageHeader className="ClimateFootprintHeader circular-revenue__header" route={page} />
      <div className="PageContent">
        <Stripe title="Circular Revenue" isBeta>
          <DataSourceAndModalButton
            dataSource="Delivered sales"
            lastUpdated={lastUpdated}
            updateFrequency="weekly"
            onClick={() => setModalState({ isOpen: true, page })}
          />
        </Stripe>
        {/* <div className="InlineMessageWrapper">
          <InlineMessage
            body={
              "Due to ongoing work to automate visitors' data to Sustain, Water Efficiency will not be updated this month. We apologise for the inconvenience."
            }
            variant="cautionary"
          />
        </div> */}
        {rawBenchmarks?.length === 0 ? (
          <NoDataView />
        ) : (
          <>
            <CardRow className="BenchmarkingAndGoals" disableWidthClass>
              <Benchmarking
                key="benchmarking"
                benchmarks={
                  benchmarks.comparison
                    ? [...(benchmarks.selectedLocation ? [benchmarks.selectedLocation] : []), ...benchmarks.comparison]
                    : []
                }
                label="Circular Revenue"
                headers={benchmarkHeaders}
                locationId={locationId}
                openModal={() => setBenchmarkModalOpen(true)}
                totalLocations={benchmarks.allLocationsWithoutSelected.length ?? 0}
                isArea
              />
              <KPIPerformance
                key="goals"
                heading="KPI Performance"
                units={['YTD']}
                kpis={
                  performance && !benchmarks.selectedLocation
                    ? []
                    : [
                        {
                          key: 'Number Of Circular Transactions',
                          value: revenuePerformance?.circularTransactions
                            ? `${formatAbsoluteNumber(revenuePerformance?.circularTransactions)}`
                            : ''
                        },
                        // {
                        //   key: 'Direct Profit',
                        //   value: revenuePerformance?.totalProfit
                        //     ? `${formatAbsoluteNumber(revenuePerformance?.directProfit)} ${currencyCode}`
                        //     : ''
                        // },
                        // {
                        //   key: 'Total Profit',
                        //   value: revenuePerformance?.totalProfit
                        //     ? `${formatAbsoluteNumber(revenuePerformance?.totalProfit)} ${currencyCode}`
                        //     : ''
                        // },
                        {
                          key: 'Circular Revenue',
                          value: revenuePerformance?.directRevenue
                            ? `${formatAbsoluteNumber(revenuePerformance?.directRevenue)} ${currencyCode}`
                            : '',
                          keyClassNames: ['Bold']
                        }
                        // {
                        //   key: 'Total Revenue',
                        //   value: revenuePerformance?.totalRevenue
                        //     ? `${formatAbsoluteNumber(revenuePerformance?.totalRevenue)} ${currencyCode}`
                        //     : '',
                        //   keyClassNames: ['Bold'],
                        //   valueClassNames: ['Bold']
                        // isUnitRegular: true,
                        // colorClass:
                        //   !benchmarks.selectedLocation?.goal ||
                        //   (benchmarks.selectedLocation?.goal ?? 0) >= (benchmarks.selectedLocation?.currentYtd ?? 0)
                        //     ? GoalStatus.OnTrack
                        //     : GoalStatus.NotOnTrack
                        // }
                      ]
                }
              />
            </CardRow>
            {/* <FilterSelector
              filters={filters}
              selectedFilter={selectedFilter}
              setSelectedFilter={setSelectedFilter}
              filterLabels={filterLabels}
            /> */}
            <MainCard
              title="Circular Revenue"
              subtitle={
                revenue && (
                  <>
                    {filteredRevenueSum && formatAbsoluteNumber(Math.round(filteredRevenueSum))}{' '}
                    <span className="Label">{currencyCode}</span>
                  </>
                )
              }
            >
              <div className="GraphContainer">
                {revenue && revenue?.length > 0 ? (
                  <CircularRevenueGraph revenue={revenue} currencyCode={currencyCode} />
                ) : (
                  <LoadingSkeleton />
                )}
              </div>
            </MainCard>
          </>
        )}
        <RevenueGraphSideBarCards
          revenue={revenue}
          currencyCode={currencyCode}
          className={classNames('GraphRow', 'three-in-row')}
          isLoading={isLoading}
        />
      </div>
      <KpiPageLearnMoreModal
        lastUpdated={lastUpdated}
        modalState={modalState}
        onClose={() => setModalState({ isOpen: false })}
      />
      {rawBenchmarks && rawBenchmarks.length > 0 && (
        <BenchmarkingModal
          benchmarks={benchmarks.allLocationsWithoutSelected}
          closeFn={() => setBenchmarkModalOpen(false)}
          footerBenchmark={benchmarks.country}
          headers={benchmarkHeaders}
          isOpen={benchmarkModalOpen}
          locationId={getLocationId(currentLocation)}
          sortBy="label"
          sortDirection="asc"
          title="Circular Revenue"
          isArea
        />
      )}
    </div>
  )
}

interface RevenueGraphProps {
  revenue: RevenueBase[] | undefined
  currencyCode: string | undefined
  className?: string
  isLoading?: boolean
}

export const CircularRevenueGraph = ({ revenue, currencyCode }: RevenueGraphProps) => {
  const revenueSeries = React.useMemo(() => {
    return revenue
      ? [
          {
            name: 'Secondhand Products',
            color: colours.blue10,
            data: revenue.map(week => ({
              x: new Date(week.readableDate),
              y: week.totalSecondRevenue
            }))
          },
          {
            name: 'Care & Repair Products',
            color: colours.lightBlue4,
            data: revenue.map(week => ({
              x: new Date(week.readableDate),
              y: week.totalAfterSalesRevenue
            }))
          }
        ]
      : undefined
  }, [JSON.stringify(revenue)])

  return (
    <ChartContainer
      domain={Array.from(new Set(revenue?.map(({ readableDate }) => new Date(readableDate))))}
      series={revenueSeries}
      generator={stackedBarChart('none')}
      dateFormat="week"
      yAxisTitle={getCurrencySymbol(currencyCode)}
      tooltipSummary={date => {
        if (revenue === undefined) {
          return []
        }
        const weeklyData = flatMap(revenueSeries, r => ({
          name: r.name,
          value: r.data.find(d => isSameDay(d.x, date))?.y
        }))
        const sum = weeklyData.reduce((acc, d) => acc + (d?.value ?? 0), 0)
        return [{ title: 'Total', value: formatAbsoluteNumber(Math.round(sum)), icon: <SumIndicator /> }]
      }}
    />
  )
}

const RevenueGraphSideBarCards = ({ revenue, currencyCode, className, isLoading }: RevenueGraphProps) => {
  if (isLoading) {
    return <SideBarCardsLoading className={className} />
  }

  const NoDataViewSmallWhithConteiner = () => {
    return (
      <div className="GraphSideBarCard">
        <NoDataViewSmall />
      </div>
    )
  }

  const filteredSales = revenue?.find(s => s.week === 36)
  const index = filteredSales && revenue?.indexOf(filteredSales)

  const AfterSlesChart = () => {
    if (!revenue) {
      return <NoDataViewSmallWhithConteiner />
    }

    const revenueSeries = React.useMemo(() => {
      return revenue
        ? [
            {
              name: 'Care & Repair Range',
              color: colours.hotPink,
              data: revenue.map(week => ({
                x: new Date(week.readableDate),
                y: week.totalAfterSalesRevenue
              }))
            }
          ]
        : undefined
    }, [JSON.stringify(revenue)])

    const filteredRevenueSum = revenue?.slice(index).reduce((acc, currentValue) => {
      return acc + currentValue.totalAfterSalesRevenue
    }, 0)
    // const filteredRevenueSum = _.last(revenue)?.totalAfterSalesRevenue

    return (
      <div className="GraphSideBarCard circular-revenue">
        <div className="CardHeading">
          <h3>Revenue From Care & Repair Products</h3>
          {revenue && (
            <div className="Subtitle">
              {filteredRevenueSum && formatAbsoluteNumber(Math.round(filteredRevenueSum))}{' '}
              <span className="Label">{currencyCode}</span>
            </div>
          )}
        </div>
        <ChartContainer
          domain={Array.from(new Set(revenue?.map(({ readableDate }) => new Date(readableDate))))}
          series={revenueSeries}
          generator={stackedBarChart('none')}
          dateFormat="week"
          yAxisTitle={getCurrencySymbol(currencyCode)}
          isSmallGraph
          tooltipSummary={date => {
            if (revenue === undefined) {
              return []
            }
            const weeklyData = flatMap(revenueSeries, r => ({
              name: r.name,
              value: r.data.find(d => isSameDay(d.x, date))?.y
            }))
            const sum = weeklyData.reduce((acc, d) => acc + (d?.value ?? 0), 0)
            return [{ title: 'Total', value: formatAbsoluteNumber(Math.round(sum)), icon: <SumIndicator /> }]
          }}
        />
      </div>
    )
  }

  const SecondHandChart = () => {
    if (!revenue) {
      return <NoDataViewSmallWhithConteiner />
    }

    const seconHand = 'Buyback & Resell'

    const revenueSeries = React.useMemo(() => {
      return revenue
        ? [
            {
              name: seconHand,
              color: colours.purple2,
              data: revenue.map(week => ({
                x: new Date(week.readableDate),
                y: week.totalSecondRevenue
              }))
            }
          ]
        : undefined
    }, [JSON.stringify(revenue)])

    const filteredRevenueSum = revenue?.slice(index).reduce((acc, currentValue) => {
      return acc + currentValue.totalSecondRevenue
    }, 0)
    // const filteredRevenueSum = _.last(revenue)?.totalSecondRevenue

    return (
      <div className="GraphSideBarCard circular-revenue">
        <div className="CardHeading">
          <h3>Revenue From Secondhand Products</h3>
          {revenue && (
            <div className="Subtitle">
              {filteredRevenueSum && formatAbsoluteNumber(Math.round(filteredRevenueSum))}{' '}
              <span className="Label">{currencyCode}</span>
            </div>
          )}
        </div>
        <ChartContainer
          domain={Array.from(new Set(revenue?.map(({ readableDate }) => new Date(readableDate))))}
          series={revenueSeries}
          generator={stackedBarChart('none')}
          dateFormat="week"
          yAxisTitle={getCurrencySymbol(currencyCode)}
          isSmallGraph
          tooltipSummary={date => {
            if (revenue === undefined) {
              return []
            }
            const weeklyData = flatMap(revenueSeries, r => ({
              name: r.name,
              value: r.data.find(d => isSameDay(d.x, date))?.y
            }))
            const sum = weeklyData.reduce((acc, d) => acc + (d?.value ?? 0), 0)
            return [{ title: 'Total', value: formatAbsoluteNumber(Math.round(sum)), icon: <SumIndicator /> }]
          }}
        />
      </div>
    )
  }

  return (
    <div className={className}>
      <AfterSlesChart />
      <SecondHandChart />
    </div>
  )
}

export function selectBenchmarks(
  rawBenchmarks: RevenueBenchmark[],
  locationId: string,
  currentLocation: CurrentLocation
) {
  const benchmarks = sortBenchmarks(rawBenchmarks, 'label', locationId)
  const countryBenchmark = benchmarks?.find(benchmark => benchmark.id === getLocationId(currentLocation)) ?? {
    id: getLocationId(currentLocation),
    label: getLocationLabel(currentLocation),
    currFyTotalRevenue: sumBy(benchmarks, 'currFyTotalRevenue'),
    prevFyTotalRevenue: sumBy(benchmarks, 'prevFyTotalRevenue')
  }
  const selectedLocationBenchmark = isSiteId(locationId) ? benchmarks.find(b => b.id === locationId) : countryBenchmark
  const comparisonBenchmarks = benchmarks.filter(b => b.id !== locationId).slice(0, selectedLocationBenchmark ? 3 : 4)
  const isSite = isSiteId(locationId)
  const allWithoutSelected = benchmarks.filter(b => b.id !== locationId && (isSite ? b.id?.length >= 3 : true)) ?? []

  return {
    country: countryBenchmark,
    selectedLocation: selectedLocationBenchmark,
    comparison: comparisonBenchmarks,
    allLocationsWithoutSelected: allWithoutSelected,
    allLocations: benchmarks
  }
}

export function selectBenchmarkingHeaders(currentFYShort: number): HeadingItem<RevenueBenchmark>[] {
  return [
    [
      {
        name: `FY${currentFYShort - 1} YTD`,
        key: 'prevFyTotalRevenue',
        formatValue: n => (isNaN(Number(n)) ? null : formatAbsoluteNumber(Number(n))),
        valueClassNames: 'Right'
      }
    ],
    [
      {
        name: `FY${currentFYShort} YTD`,
        key: 'currFyTotalRevenue',
        formatValue: n => (isNaN(Number(n)) ? null : formatAbsoluteNumber(Number(n))),
        valueClassNames: 'Right'
      }
    ]
    // [{ name: `FY${currentFYShort} Goal`, key: 'goal' }],
    // [{ name: `FY${currentFYShort + 1} Goal`, key: 'goalNextFY' }]
  ]
}
