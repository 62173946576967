import React from 'react'
import { BenchmarkingModal } from '../../../../components/KPIPage'
import { useDataContext } from './DataProvider'
import { getLocationId } from '../../../../components/Utils/utils'
import { useDataAvailabilityContext, useLocations } from '../../../../context'
import { selectBenchmarkingHeaders } from '../utils/mappers'

export const BenchmarkDetails = () => {
  const { benchmarks, benchmarkModalOpen, setBenchmarkModalOpen } = useDataContext()
  const { dataAvailability } = useDataAvailabilityContext()
  const { currentLocation } = useLocations()
  const locationId = getLocationId(currentLocation)
  const benchmarkingHeaders = selectBenchmarkingHeaders(dataAvailability)

  return (
    <BenchmarkingModal
      benchmarks={benchmarks.allLocationsWithoutSelected}
      closeFn={() => setBenchmarkModalOpen(false)}
      footerBenchmark={benchmarks.country}
      headers={benchmarkingHeaders}
      isOpen={benchmarkModalOpen && Boolean(benchmarks.allLocations.length)}
      locationId={locationId}
      sortBy="label"
      sortDirection="asc"
      title="% of waste being recycled"
    />
  )
}
