import { format, isSameMonth } from 'date-fns'
import React, { Fragment } from 'react'
import classNames from 'classnames'

import { CountryCode, EnergyEfficiencyBenchmark, EnergyEfficiencyRolling } from '../../../../api/src/common-types'
import { ChartContainer, isDataPoint, Serie } from '../../../components/BaseGraphs/ChartContainer'
import { lineChart } from '../../../components/BaseGraphs/GraphUtil'
import { TopBar } from '../../../components/TopBar'
import { formatAbsoluteNumber, formatRelativeNumber } from '../../../components/Utils/format'
import { getEnergyEfficiencyBenchmarking, getEnergyEfficiencyRolling, getGoals } from '../../../lib/APIClient'
import { useSharedSelections } from '../../../SharedSelections'

import '../KPIPage.scss'
import colours from '../../../Colours.module.scss'
import {
  getCountry,
  getLocationId,
  getLocationLabel,
  isSiteId,
  mapGoalsResponse,
  Goal
} from '../../../components/Utils/utils'
import { KpiModalState, KpiPageLearnMoreModal } from '../../../components/Modal'
import { PageHeader } from '../../../components/PageHeader'
import {
  Benchmarking,
  BenchmarkingModal,
  CardRow,
  DataSourceAndModalButton,
  HeadingItem,
  KPIPerformance,
  MainCard,
  sortBenchmarks,
  Stripe
} from '../../../components/KPIPage'
import { NoDataView } from '../../../components/BaseGraphs/NoDataView'
import { useDataAvailabilityContext, useLocations, CurrentLocation } from '../../../context'
import { useDocumentTitle } from '../../../components/Utils/use-document-title'
import { GoalStatus } from '../../../components/SnapshotTopCards'
import { Route } from '../../../routes'
import InlineMessage from '@ingka/inline-message'

export const EnergyEfficiencyKPIPage = () => {
  useDocumentTitle('Energy Efficiency')

  const [{ func }] = useSharedSelections()
  const { dataAvailability } = useDataAvailabilityContext()
  const { currentLocation, locations } = useLocations()
  const [footprint, setFootprint] = React.useState<EnergyEfficiencyRolling[]>()
  const [dates, setDates] = React.useState<Date[]>()
  const [lastUpdated, setLastUpdated] = React.useState('')
  const [modalState, setModalState] = React.useState<KpiModalState>({ isOpen: false })
  const [goalsResponse, setGoalsResponse] = React.useState<Record<string, Goal>>()
  const [rawBenchmarks, setRawBenchmarks] = React.useState<EnergyEfficiencyBenchmark[]>()
  const [benchmarkModalOpen, setBenchmarkModalOpen] = React.useState(false)
  const locationId = getLocationId(currentLocation)
  const currentFY = dataAvailability?.energyCurrentFY ?? 2024
  const currentFYShort = currentFY - 2000

  const page = Route.EnergyEfficiencyKPIPage

  React.useEffect(() => {
    if (!locations || locations.length === 0) return

    setFootprint(undefined)
    setRawBenchmarks(undefined)
    setLastUpdated('')

    const countryCode = isSiteId(locationId)
      ? getCountry(locationId, locations).countryCode
      : (locationId as CountryCode)

    getGoals(countryCode, func, currentFY)
      .then(result =>
        mapGoalsResponse(result, 'energyEfficiencyGoal', 'energyEfficiencyGoalNextFY', 'energyEfficiencyGoalPrevFY')
      )
      .then(setGoalsResponse)
    getEnergyEfficiencyBenchmarking(countryCode, func, currentFY).then(setRawBenchmarks)
    getEnergyEfficiencyRolling(locationId, func, currentFY).then(result => {
      setFootprint(result.data)
      setDates(result.dates.map(d => new Date(d)))
      result.lastUpdated && setLastUpdated(format(new Date(result.lastUpdated), 'dd/MM/yyyy'))
    })
  }, [JSON.stringify(locations), JSON.stringify(func), JSON.stringify(currentLocation)])

  const benchmarkingHeaders = selectBenchmarkingHeaders(currentFYShort)
  const benchmarks = selectBenchmarks(rawBenchmarks || [], goalsResponse, locationId, currentLocation)

  const ytdSummary = benchmarks.selectedLocation?.rolling ? (
    <span>
      {formatRelativeNumber(benchmarks.selectedLocation.rolling)} <span className="Label">kWh/m²</span>
    </span>
  ) : undefined

  return (
    <div className="KPIPage">
      <TopBar currentPage={Route.EnergyEfficiencyKPIPage} useInFlexLayout />
      <PageHeader className="ClimateFootprintHeader" route={Route.EnergyEfficiencyKPIPage} />
      <div className="PageContent">
        <Stripe title="Energy Efficiency">
          <DataSourceAndModalButton
            dataSource="Sustain"
            lastUpdated={lastUpdated}
            onClick={() => setModalState({ isOpen: true, page })}
          />
        </Stripe>
        <div className="InlineMessageWrapper">
          <InlineMessage
            body="The scope of energy efficiency is changed in FY25, Retail goals now include CFF and tenants while the FY24 goal scope did not. Also sites with less than 12m energy data are excluded in the performance not to skew the results. Centres data do not show due to Sustain update needed first"
            variant="cautionary"
          />
        </div>
        {rawBenchmarks?.length === 0 || footprint?.length === 0 ? (
          <NoDataView />
        ) : (
          <>
            <CardRow className="BenchmarkingAndGoals">
              <Benchmarking
                benchmarks={
                  benchmarks.comparison
                    ? [...(benchmarks.selectedLocation ? [benchmarks.selectedLocation] : []), ...benchmarks.comparison]
                    : []
                }
                label="building kWh/m²"
                headers={benchmarkingHeaders}
                locationId={locationId}
                openModal={() => setBenchmarkModalOpen(true)}
                totalLocations={benchmarks.allLocations.length ?? 0}
              />
              <KPIPerformance
                key="goals"
                heading="KPI Performance"
                units={['Rolling 12 mos']}
                kpis={
                  footprint?.length === 0
                    ? []
                    : [
                        {
                          key: 'Building kWh',
                          unit: 'building kWh',
                          value: benchmarks.selectedLocation?.buildingkwh
                            ? formatAbsoluteNumber(benchmarks.selectedLocation?.buildingkwh)
                            : ''
                        },
                        {
                          key: 'Total m²',
                          unit: 'm²',
                          value: benchmarks.selectedLocation?.area
                            ? formatAbsoluteNumber(benchmarks.selectedLocation?.area)
                            : ''
                        },
                        {
                          key: 'Energy Efficiency',
                          unit: 'building kWh/m²',
                          value: benchmarks.selectedLocation?.rolling?.toFixed(1) ?? '',
                          keyClassNames: ['Bold'],
                          valueClassNames: ['Bold'],
                          colorClass:
                            !benchmarks.selectedLocation?.goal ||
                            (benchmarks.selectedLocation?.goal ?? 0) >= (benchmarks.selectedLocation?.rolling ?? 0)
                              ? GoalStatus.OnTrack
                              : GoalStatus.NotOnTrack
                        },
                        {
                          key: `Full FY${(dataAvailability?.energyCurrentFY || 2025) - 2000} Goal`,
                          unit: 'building kWh/m²',
                          value: benchmarks.selectedLocation?.goal?.toFixed(1) ?? '',
                          keyClassNames: ['Bold'],
                          valueClassNames: ['Bold']
                        }
                      ]
                }
              />
            </CardRow>
            <MainCard title="Energy Efficiency" titleExtantion="Rolling 12 mos" subtitle={ytdSummary}>
              <div className="GraphContainer">
                <ChartContainer
                  domain={dates}
                  series={formatEnergyEfficiencySeries(footprint, dates, benchmarks.selectedLocation?.goal)}
                  generator={lineChart}
                  dateFormat="month"
                  lineChartConfiguration={{ focusStyle: 'none', startFromZero: false }}
                  yAxisTitle="kWh/m²"
                  showDecimals
                />
              </div>
            </MainCard>
          </>
        )}
      </div>
      <KpiPageLearnMoreModal
        lastUpdated={lastUpdated}
        modalState={modalState}
        onClose={() => setModalState({ isOpen: false })}
      />
      {rawBenchmarks && rawBenchmarks.length > 0 && (
        <BenchmarkingModal
          benchmarks={benchmarks.allLocations}
          closeFn={() => setBenchmarkModalOpen(false)}
          footerBenchmark={benchmarks.country}
          headers={benchmarkingHeaders}
          isOpen={benchmarkModalOpen}
          locationId={getLocationId(currentLocation)}
          sortBy="label"
          sortDirection="asc"
          title="building kWh/m²"
        />
      )}
    </div>
  )
}

export const formatEnergyEfficiencySeries = (
  data: EnergyEfficiencyRolling[] | undefined,
  domain: Date[] | undefined,
  goal?: number
): Serie[] | undefined => {
  if (data === undefined || domain === undefined) {
    return undefined
  }
  const noData = data.every(d => !d.currentFY && !d.previousFY)
  if (noData) {
    return []
  }
  const fy = data[0].fiscalYear

  const dataWithDates = domain.map(date => {
    const d = data.find(d => isSameMonth(new Date(d.readableDate), date))
    return {
      ...d,
      date,
      curr: d?.currentFY,
      prev: d?.previousFY
    }
  })

  return [
    {
      name: `FY${fy - 1}`,
      color: colours.offWhite1,
      fill: colours.grey1,
      data: dataWithDates.map(d => ({ x: d.date, y: d.prev })).filter(isDataPoint)
    },
    {
      name: `FY${fy}`,
      color: colours.darkBlue1,
      data: dataWithDates.map(d => ({ x: d.date, y: d.curr })).filter(isDataPoint)
    },
    !!goal && {
      name: `FY${fy} Goal`,
      color: colours.lightBlue2,
      data: data.map(d => ({ x: new Date(d.readableDate), y: goal }))
    }
  ].filter((s): s is Serie => s !== false)
}

export function selectBenchmarks(
  rawBenchmarks: EnergyEfficiencyBenchmark[],
  goalsMapped: Record<string, Goal> | undefined,
  locationId: string,
  currentLocation: CurrentLocation
) {
  const rawBenchmarksWithGoals = sortBenchmarks(
    rawBenchmarks.map(benchmark => ({
      ...benchmark,
      goal: goalsMapped?.[benchmark.id]?.goal as number,
      goalPrevFY: goalsMapped?.[benchmark.id]?.goalPrevFY as number,
      goalNextFY: goalsMapped?.[benchmark.id]?.goalNextFY as number
    })),
    'label',
    locationId
  )
  const countryBenchmark: EnergyEfficiencyBenchmark = rawBenchmarksWithGoals?.find(
    benchmark => benchmark.id === getLocationId(currentLocation)
  ) ?? {
    id: getLocationId(currentLocation),
    label: getLocationLabel(currentLocation),
    area: 0,
    goalPrevFY: 0,
    goal: 0,
    goalNextFY: 0,
    selectable: true,
    previousFy: 0,
    previousYtd: 0,
    currentYtd: 0,
    buildingkwh: 0,
    rolling: 0
  }
  const selectedLocationBenchmark = isSiteId(locationId)
    ? rawBenchmarksWithGoals.find(b => b.id === locationId)
    : countryBenchmark
  const comparisonBenchmarks = rawBenchmarksWithGoals
    .filter(b => b.id !== locationId)
    .slice(0, selectedLocationBenchmark ? 3 : 4)
  const isSite = isSiteId(locationId)
  const benchmarksWithoutCurrentLocation =
    rawBenchmarksWithGoals?.filter(b => b.id !== locationId && (isSite ? b.id.length >= 3 : true)) ?? []

  return {
    country: countryBenchmark,
    selectedLocation: selectedLocationBenchmark,
    comparison: comparisonBenchmarks,
    allLocations: benchmarksWithoutCurrentLocation
  }
}

export function selectBenchmarkingHeaders(currentFYShort: number): HeadingItem<EnergyEfficiencyBenchmark>[] {
  return [
    [
      {
        name: 'Rolling 12 mos',
        key: 'rolling',
        formatValue: n => (isNaN(Number(n)) || !n ? null : formatRelativeNumber(Number(n))),
        valueClassNames: benchmark =>
          classNames(
            'Right',
            { YTD: benchmark.goal },
            !benchmark.goal ? 'Black' : Number(benchmark.rolling) < benchmark.goal ? 'OnTrack' : 'YTD'
          )
      }
    ],
    [
      {
        name: `FY${currentFYShort - 1} Goal`,
        key: 'goalPrevFY',
        formatValue: n => (isNaN(Number(n)) || !n ? null : formatRelativeNumber(Number(n))),
        valueClassNames: 'Right'
      }
    ],
    [
      {
        name: `FY${currentFYShort} Goal`,
        key: 'goal',
        formatValue: n => (isNaN(Number(n)) || !n ? null : formatRelativeNumber(Number(n))),
        valueClassNames: 'Right'
      }
    ],
    [
      {
        name: `FY${currentFYShort + 1} Goal`,
        key: 'goalNextFY',
        formatValue: n => (isNaN(Number(n)) || !n ? null : formatRelativeNumber(Number(n))),
        valueClassNames: 'Right'
      }
    ]
  ]
}
