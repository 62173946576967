import React, { Fragment } from 'react'
import { TopBar } from '../../../components/TopBar'
import { Route } from '../../../routes'
import { PageHeader } from '../../../components/PageHeader'
import {
  Benchmarking,
  BenchmarkingModal,
  CardRow,
  DataSourceAndModalButton,
  HeadingItem,
  KPIPerformance,
  sortBenchmarks,
  Stripe
} from '../../../components/KPIPage'
import { useDocumentTitle } from '../../../components/Utils/use-document-title'
import { CustomerTravelContext, CustomerTravelProvider } from './CustomerTravelProvider'
import { Show } from '../../../components/Conditions/Show'
import { CustomerTravelBenchmark } from '../../../../api/src/types/benchmark'
import { getLocationId, getLocationLabel, isSiteId } from '../../../components/Utils/utils'
import { CurrentLocation, useLocations } from '../../../context'
import { formatAbsoluteNumber, formatRelativeNumber, multiFormatNumber } from '../../../components/Utils/format'
import { CustomerTravelMonthKPI } from './CustomerTravelMonthKPI'
import { FootprintByTransportTypeKPI } from './FootprintByTransportTypeKPI'
import { AnalyticsToolsModal, KpiModalState, KpiPageLearnMoreModal } from '../../../components/Modal'
import { PeopleByTransportTypeKPI } from './PeopleByTransportType'
import { FootprintYtdKPI } from './FootprintYtdKPI'
import { CustomerDistanceKPI } from './CustomerDistanceKPI'
import InlineMessage from '@ingka/inline-message'
import { useDynamicLabel } from './data/useDynamicLabel'
import { NoDataView } from '../../../components/BaseGraphs/NoDataView'

const CustomerTravelImpl = () => {
  useDocumentTitle('Customer Travel')

  const [benchmarkingModalOpen, setBenchmarkingModalOpen] = React.useState(false)
  const [modalState, setModalState] = React.useState<KpiModalState>({ isOpen: false })

  const personLabel = useDynamicLabel()

  const { currentLocation } = useLocations()
  const {
    benchmarks: rawBenchmarks,
    locationId,
    benchmarkingFY,
    lastUpdated,
    noData
  } = React.useContext(CustomerTravelContext)
  const [modalToolsState, setModalToolsState] = React.useState<boolean>(false)

  // Hooks end ----

  const WarningMessage = (
    <div className="InlineMessageWrapper">
      <InlineMessage
        body="Note that the scope of this measurement is customers travelling to stores for retail, and visitors travelling to Ingka Centres meeting places."
        variant="cautionary"
      />
    </div>
  )

  const benchmarkHeaders = selectBenchmarkingHeaders(benchmarkingFY || undefined)
  const benchmarks = selectBenchmarks(rawBenchmarks || [], locationId, currentLocation)

  return (
    <div className="KPIPage">
      <TopBar currentPage={Route.CustomerTravelKPIPage} useInFlexLayout />
      <PageHeader className="ClimateFootprintHeader" route={Route.CustomerTravelKPIPage}></PageHeader>
      <div className="PageContent">
        <Stripe title="Climate Footprint — Customer Travel" isBeta lastUpdated={lastUpdated}>
          <DataSourceAndModalButton
            onClick={() => setModalState({ isOpen: true, page: Route.CustomerTravelKPIPage })}
            isCustomer
            onClickHandler={() => {
              setModalToolsState(true)
            }}
          />
        </Stripe>
        {WarningMessage}
        <Show when={noData}>
          <NoDataView />
        </Show>
        <Show when={!noData}>
          <>
            <CardRow className="BenchmarkingAndGoals" disableWidthClass>
              <Benchmarking
                key="benchmarking"
                benchmarks={
                  benchmarks.comparison
                    ? [...(benchmarks.selectedLocation ? [benchmarks.selectedLocation] : []), ...benchmarks.comparison]
                    : []
                }
                label={`kg CO2e/${personLabel.single}`}
                headers={benchmarkHeaders}
                locationId={locationId}
                openModal={() => setBenchmarkingModalOpen(true)}
                totalLocations={benchmarks.allLocationsWithoutSelected.length ?? 0}
              />
              <KPIPerformance
                key="goals"
                heading="KPI Performance"
                units={['YTD']}
                kpis={
                  !benchmarks.selectedLocation
                    ? []
                    : [
                        {
                          key: 'Total CO2e from customer travel',
                          unit: 'tonnes CO2e',
                          value: benchmarks.selectedLocation?.total_footprint
                            ? formatAbsoluteNumber(benchmarks.selectedLocation?.total_footprint / 1000)
                            : ''
                        },
                        {
                          key: 'Average Distance Travelled',
                          unit: `km/${personLabel.single}`,
                          value: benchmarks.selectedLocation?.distance_person
                            ? formatAbsoluteNumber(benchmarks.selectedLocation?.distance_person)
                            : ''
                        },
                        {
                          key: `Average CO2e per ${personLabel.single}`,
                          unit: `kg CO2e/${personLabel.single}`,
                          keyClassNames: ['Bold'],
                          valueClassNames: ['Bold'],
                          isUnitRegular: true,
                          value: benchmarks.selectedLocation?.currentYtd
                            ? formatRelativeNumber(benchmarks.selectedLocation?.currentYtd)
                            : ''
                        }
                      ]
                }
              />
            </CardRow>
            <CustomerTravelMonthKPI />
            <CardRow>
              <FootprintByTransportTypeKPI />
              <PeopleByTransportTypeKPI />
            </CardRow>
            <CardRow>
              <FootprintYtdKPI />
              <CustomerDistanceKPI />
            </CardRow>
          </>
        </Show>
      </div>
      <KpiPageLearnMoreModal
        lastUpdated={lastUpdated}
        modalState={modalState}
        onClose={() => setModalState({ isOpen: false })}
      />
      <Show when={Number(rawBenchmarks?.length) > 0}>
        <BenchmarkingModal
          benchmarks={benchmarks.allLocationsWithoutSelected}
          closeFn={() => setBenchmarkingModalOpen(false)}
          footerBenchmark={benchmarks.country}
          headers={benchmarkHeaders}
          isOpen={benchmarkingModalOpen}
          locationId={getLocationId(currentLocation)}
          sortBy="label"
          sortDirection="asc"
          title={`kg CO2e/${personLabel.single}`}
        />
      </Show>
      <div className="KPIPage__modal-header">
        <AnalyticsToolsModal
          lastUpdated={lastUpdated}
          modalState={modalToolsState}
          onClose={() => setModalToolsState(false)}
        />
      </div>
    </div>
  )
}

export const CustomerTravel = () => {
  return (
    <CustomerTravelProvider>
      <CustomerTravelImpl />
    </CustomerTravelProvider>
  )
}

export function selectBenchmarks(
  rawBenchmarks: CustomerTravelBenchmark[],
  locationId: string,
  currentLocation: CurrentLocation
) {
  const benchmarks = sortBenchmarks(rawBenchmarks, 'label', locationId)
  const countryBenchmark = benchmarks?.find(benchmark => benchmark.id === getLocationId(currentLocation)) ?? {
    id: getLocationId(currentLocation),
    label: getLocationLabel(currentLocation),
    selectable: true,
    previousFy: 0,
    previousYtd: 0,
    currentYtd: 0,
    rolling: 0,
    total_footprint: 0,
    distance_person: 0
  }
  const selectedLocationBenchmark = isSiteId(locationId) ? benchmarks.find(b => b.id === locationId) : countryBenchmark
  const comparisonBenchmarks = benchmarks.filter(b => b.id !== locationId).slice(0, selectedLocationBenchmark ? 3 : 4)
  const isSite = isSiteId(locationId)
  const allWithoutSelected = benchmarks.filter(b => b.id !== locationId && (isSite ? b.id.length >= 3 : true)) ?? []

  return {
    country: countryBenchmark,
    selectedLocation: selectedLocationBenchmark,
    comparison: comparisonBenchmarks,
    allLocationsWithoutSelected: allWithoutSelected,
    allLocations: benchmarks
  }
}

export function selectBenchmarkingHeaders(benchmarkingFY?: number): HeadingItem<CustomerTravelBenchmark>[] {
  return [
    [
      {
        name: `FY${Number(benchmarkingFY) - 2001} YTD`,
        key: 'previousYtd',
        formatValue: n => (isNaN(Number(n)) ? null : multiFormatNumber(Number(n))),
        valueClassNames: 'Right'
      }
    ],
    [
      {
        name: `FY${Number(benchmarkingFY) - 2000} YTD`,
        key: 'currentYtd',
        formatValue: n => (isNaN(Number(n)) ? null : multiFormatNumber(Number(n))),
        valueClassNames: 'Right'
      }
    ]
  ]
}
