import * as React from 'react'
import colours from '../../Colours.module.scss'

export const InactiveIndicator = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width={16} height={16} fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 8a6 6 0 01-9.23 5.058l8.288-8.287C13.654 5.703 14 6.81 14 8zM3.287 11.713l8.426-8.426a6 6 0 00-8.427 8.427zM16 8A8 8 0 110 8a8 8 0 0116 0z"
        fill="color"
        fillOpacity={0.8}
      />
    </svg>
  )
}

interface ActiveIndicatorProps extends React.SVGProps<SVGSVGElement> {
  width?: number
}

export const ActiveIndicator = (props: ActiveIndicatorProps) => {
  const { width, ...rest } = props
  const widthOrDefault = width ?? 16
  const radius = widthOrDefault / 2
  return (
    <svg width={widthOrDefault} height={widthOrDefault} {...rest}>
      <pattern
        id="small-green-strokes-pattern"
        width="5"
        height="10"
        patternUnits="userSpaceOnUse"
        patternTransform="rotate(135 10 90)"
      >
        <line stroke="#00946b" strokeWidth="5px" y2="10" />
      </pattern>
      <pattern
        id="small-orange-strokes-pattern"
        width="5"
        height="10"
        patternUnits="userSpaceOnUse"
        patternTransform="rotate(135 10 90)"
      >
        <line stroke="#fc6812" strokeWidth="5px" y2="10" />
      </pattern>
      <pattern
        id="small-dkblue-strokes-pattern"
        width="10"
        height="10"
        patternUnits="userSpaceOnUse"
        patternTransform="rotate(45 10 180)"
      >
        <line stroke="#00324A" strokeWidth="10px" y2="10" />
      </pattern>
      <pattern
        id="small-yellow-strokes-pattern"
        width="10"
        height="10"
        patternUnits="userSpaceOnUse"
        patternTransform="rotate(45 10 180)"
      >
        <line stroke="#F0E442" strokeWidth="10px" y2="10" />
      </pattern>
      <pattern
        id="small-red-strokes-pattern"
        width="10"
        height="10"
        patternUnits="userSpaceOnUse"
        patternTransform="rotate(45 10 180)"
      >
        <line stroke="#EF5675" strokeWidth="10px" y2="10" />
      </pattern>
      <circle cx={radius} cy={radius} r={radius} fill="color" />
    </svg>
  )
}

export const RectIndicator = (props: ActiveIndicatorProps) => {
  const { width, ...rest } = props
  const widthOrDefault = width ?? 16
  return (
    <svg width={widthOrDefault} height={widthOrDefault} {...rest}>
      <rect width={width} height={width} rx={2} fill="color" />
    </svg>
  )
}

export const SumIndicator = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 1.778a6.222 6.222 0 100 12.444A6.222 6.222 0 008 1.778zM0 8a8 8 0 1116 0A8 8 0 010 8zm5-.5V6h6v1.5H5zm0 1V10h6V8.5H5z"
      fill={colours.black2}
    />
  </svg>
)

export const ExclamationIndicator = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 14 14" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 1.815a5.185 5.185 0 100 10.37 5.185 5.185 0 000-10.37zM.333 7a6.667 6.667 0 1113.334 0A6.667 6.667 0 01.333 7zm5.926.74V4.038h1.482v3.704H6.259zm0 2.223V8.48h1.482v1.482H6.259z"
      fill={props.fill}
    />
  </svg>
)

export const InformationIndicator = ({ fill, ...rest }: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 14 14" fill={fill ?? colours.black2} {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 12.1852C9.8637 12.1852 12.1852 9.86369 12.1852 6.99999C12.1852 4.13629 9.8637 1.8148 7 1.8148C4.1363 1.8148 1.81482 4.13629 1.81482 6.99999C1.81482 9.86369 4.1363 12.1852 7 12.1852ZM13.6667 6.99999C13.6667 10.6819 10.6819 13.6667 7 13.6667C3.3181 13.6667 0.333335 10.6819 0.333336 6.99999C0.333336 3.31809 3.3181 0.333324 7 0.333324C10.6819 0.333324 13.6667 3.31809 13.6667 6.99999ZM7.74074 6.25925L7.74074 9.96295L6.25926 9.96295L6.25926 6.25925L7.74074 6.25925ZM7.74074 4.03703L7.74074 5.51851L6.25926 5.51851L6.25926 4.03703L7.74074 4.03703Z"
    />
  </svg>
)

export const BlackInformationIndicator = () => (
  <svg viewBox="0 0 14 14" fill="none">
    <path
      d="M7 12.1852C9.8637 12.1852 12.1852 9.86369 12.1852 6.99999C12.1852 4.13629 9.8637 1.8148 7 1.8148C4.1363 1.8148 1.81482 4.13629 1.81482 6.99999C1.81482 9.86369 4.1363 12.1852 7 12.1852Z"
      stroke={colours.black2}
      fill={colours.black2}
    />
    <path
      d="M7.74074 6.25925L7.74074 9.96295L6.25926 9.96295L6.25926 6.25925L7.74074 6.25925ZM7.74074 4.03703L7.74074 5.51851L6.25926 5.51851L6.25926 4.03703L7.74074 4.03703Z"
      fill={'white'}
    />
  </svg>
)

export const InformationCircle = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 9.5c.6904 0 1.25-.5596 1.25-1.25S12.6904 7 12 7s-1.25.5596-1.25 1.25S11.3096 9.5 12 9.5zM11 11v6h2v-6h-2z"
      fill={'white'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2C6.4771 2 2 6.4771 2 12c0 5.5228 4.4771 10 10 10 5.5228 0 10-4.4772 10-10 0-5.5229-4.4772-10-10-10zM4 12c0-4.4183 3.5817-8 8-8s8 3.5817 8 8-3.5817 8-8 8-8-3.5817-8-8z"
      fill={'white'}
    />
  </svg>
)
181
