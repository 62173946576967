import React from 'react'
import { GridHr, Title } from '../../../components/Benchmark/GridHelpers'
import { BenchmarkingContext } from './Provider'
import { LoadingSkeleton } from '../../../components/LoadingSkeleton'
import { BenchmarkTableRow, TableRowKey } from '../../../components/Benchmark/BenchmarkTableRow'
import { getLocationOrDefault } from '../../../components/Utils/utils'
import * as ChangeMakersKPIPage from '../../KPIPages/ChangeMakers/ChangeMakersKPIPage'
import * as SocialImpactKPIPage from '../../KPIPages/SocialImpact/SocialImpactKPIPage'
import { useDataAvailabilityContext, useLocations } from '../../../context'
import { useSharedSelections } from '../../../SharedSelections'

export const SocialImpactTable = () => {
  return (
    <>
      <div className="TableHeadTitle Social">Fair & EQUAL</div>
      <div className="Table">
        <Title className="KPI">KPI / PI</Title>
        <Title className="SiteCountry">Site / country</Title>
        <GridHr isHead />
        <SocialImpactTableRow />
        <GridHr />
        <ChangeMakersTableRow />
      </div>
    </>
  )
}

function SocialImpactTableRow() {
  const context = React.useContext(BenchmarkingContext)

  if (!context) return null

  const locationId = getLocationOrDefault()
  const [{ functionArea }] = useSharedSelections()
  const { currentLocation } = useLocations()
  const { dataAvailability } = useDataAvailabilityContext()

  const benchmarkingHeaders = SocialImpactKPIPage.selectBenchmarkingHeaders(dataAvailability, functionArea)
  const benchmarks = SocialImpactKPIPage.selectBenchmarks(
    context.data.socialImpact || [],
    context.data.socialImpactRatio,
    dataAvailability,
    context.data.goals,
    locationId,
    currentLocation
  )

  if (benchmarks.allLocations.length === 0) return <LoadingSkeleton />

  return (
    <BenchmarkTableRow
      kpiUnits="no. of people positively impacted"
      rowKey={TableRowKey.SocialImpact}
      mainBenchmark={benchmarks.selectedLocation}
      childBenchmarks={benchmarks.allLocations}
      headings={benchmarkingHeaders}
      expandedKey={context.data.expandedKey}
      toggleExpanded={context.toggleExpanded}
    />
  )
}

function ChangeMakersTableRow() {
  const context = React.useContext(BenchmarkingContext)

  if (!context) return null

  const locationId = getLocationOrDefault()
  const [{ functionArea }] = useSharedSelections()
  const { currentLocation } = useLocations()
  const { dataAvailability } = useDataAvailabilityContext()
  const currFy = dataAvailability?.socialImpactCurrentFY ?? 2000

  const benchmarkingHeaders = ChangeMakersKPIPage.selectBenchmarkingHeaders(dataAvailability, functionArea)
  const benchmarks = ChangeMakersKPIPage.selectBenchmarks(
    context.data.changeMakers || [],
    context.data.changeMakersRatio,
    context.data.changeMakersActualPeriod || undefined,
    context.data.goals,
    currFy,
    locationId,
    currentLocation
  )

  if (benchmarks.allLocations.length === 0) return <LoadingSkeleton />

  return (
    <BenchmarkTableRow
      kpiUnits="no. of change makers/no. of visitors of the country"
      rowKey={TableRowKey.ChangeMakers}
      mainBenchmark={benchmarks.selectedLocation}
      childBenchmarks={benchmarks.allLocations}
      headings={benchmarkingHeaders}
      expandedKey={context.data.expandedKey}
      toggleExpanded={context.toggleExpanded}
    />
  )
}
