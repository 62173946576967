import classNames from 'classnames'
import React from 'react'
import { ArrowIcon } from '../ArrowIcon'

import './TimeRangeSelector.scss'
import colours from '../../Colours.module.scss'

export interface TimeRangeSelectorProps<T extends string> {
  currentValue: T
  options: readonly T[]
  className?: string
  onValueChanged(value: T): void
}

export function TimeRangeSelector<T extends string>({
  currentValue,
  options,
  className,
  onValueChanged
}: TimeRangeSelectorProps<T>) {
  const [open, setOpen] = React.useState(false)

  return (
    <div className="TimeRangeSelector">
      <button className={classNames('TimeRangeSelector-button', className)} onClick={() => setOpen(!open)}>
        <div className="TimeRangeSelector-text">{currentValue}</div>
        <ArrowIcon angle={open ? 0 : 180} width={16} height={16} color={colours.offWhite1} />
      </button>
      <menu className={classNames('TimeRangeSelectorMenu', { Open: open })}>
        {options.map(opt => (
          <li key={opt} className="TimeRangeOption">
            <button
              className={classNames('TimeRangeOption-button Title', { Selected: currentValue === opt })}
              onClick={() => {
                onValueChanged(opt)
                setOpen(false)
              }}
            >
              {opt}
            </button>
          </li>
        ))}
      </menu>
      {open && <div className="ClickCatcher" onClick={() => setOpen(false)} />}
    </div>
  )
}
