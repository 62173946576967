import React from 'react'
import classnames from 'classnames'
import { upperFirst } from 'lodash'

import { ActiveIndicator, InformationIndicator } from './Indicators'
import { PortalWrapper } from '../Utils/PortalWrapper'
import { DateFormat } from './GraphUtil'

import './Tooltip.scss'
import { LanguageContext } from '../../context'
import { datefnsLocale, Language } from '../../Localisation'
import { addYears, format } from 'date-fns'
import { formatAbsoluteNumber, formatRelativeNumber } from '../Utils/format'

export interface TooltipPosition {
  x: number
  y: number
}

interface TooltipSectionItem {
  color: string | undefined
  title: string
  unit?: string
  value: string | number
  hideBullet?: boolean
  disabled?: boolean
  icon?: JSX.Element
  actionTypes?: Record<string, number>
}

export interface TooltipSettings {
  title: string
  subtitle: string
  valueFn?: (value: number) => number
  unit?: string
}

export interface SummaryItem {
  icon: JSX.Element | string
  title: string
  unit?: string
  value: string | number
  onTrack?: boolean
}

interface HeadingProps {
  heading?: string
  subtitle?: string
  fixedPosition?: boolean
}

export interface TooltipProps extends HeadingProps {
  body?: Array<Array<TooltipSectionItem>>
  summary?: Array<SummaryItem>
  sidebar?: boolean
  className?: string
  previousFY?: string
  graphName?: string
}

export function createTooltipIcon(
  color: string | undefined,
  hideBullet?: boolean,
  disabled?: boolean,
  icon?: JSX.Element
) {
  if (hideBullet) {
    return null
  }

  if (icon) {
    return <div className="TooltipNotificationIcon">{icon}</div>
  }

  return disabled ? (
    <div className="TooltipNotificationIcon">
      <InformationIndicator fill="black" />
    </div>
  ) : (
    <ActiveIndicator className="ColorIndicator" fill={color} />
  )
}

const createActionItemsList = (item: TooltipSectionItem, props: TooltipProps) => (
  <>
    {item.actionTypes && props.body?.[0].length !== 1 && <hr />}
    {item.actionTypes &&
      Object.keys(item.actionTypes).map(key => (
        <li key={key}>
          <div className="LegendContainer">
            <span className="Title">{upperFirst(key === 'reltp' ? 'RELTP projects' : key)}</span>
          </div>
          <div>
            <span className="Unit">{item.unit ?? ''}</span>
            <span className="Measurement">{formatAbsoluteNumber(Math.round(Number(item.actionTypes?.[key])))}</span>
          </div>
        </li>
      ))}
    {item.actionTypes && <hr />}
  </>
)

export const Tooltip = (props: TooltipProps) => {
  const items = props.body?.map((body, index) => (
    <ul
      key={`b${index}`}
      className={`ItemsList ${props.graphName === 'Climate Footprint – Zero Emission Deliveries' ? 'Deliveries' : ''}`}
    >
      {body.map(item => (
        <React.Fragment key={item.title}>
          {item.actionTypes && createActionItemsList(item, props)}
          <li key={item.title} className={item.title === `Difference to ${props.previousFY}` ? 'Difference' : ''}>
            <div className="LegendContainer">
              {createTooltipIcon(item.color, item.hideBullet, item.disabled, item.icon)}
              <span className="Title">{upperFirst(item.title)}</span>
            </div>
            <div>
              <span className="Unit">{item.unit ?? ''}</span>
              <span className="Measurement">{item.value}</span>
            </div>
          </li>
        </React.Fragment>
      ))}
    </ul>
  ))
  const summaryItems = props.summary?.map(s => (
    <li key={s.title}>
      <div className="LegendContainer">
        <span className="ColorIndicator">{typeof s.icon === 'string' ? createTooltipIcon(s.icon) : s.icon}</span>
        <span className="Title">{upperFirst(s.title)}</span>
      </div>
      <div className="Summary">
        <span className="Unit">{s.unit}</span>
        <span className={classnames('Measurement', { OnTrack: s.onTrack === true, NotOnTrack: s.onTrack === false })}>
          {s.value}
        </span>
      </div>
    </li>
  ))
  return (
    <div className={classnames('Tooltip', { SidebarTooltip: props.sidebar }, props.className)}>
      {props.heading && <TooltipHeading heading={props.heading} subtitle={props.subtitle} />}
      {items}
      {summaryItems && summaryItems.length > 0 && <ul className="ItemsList Summary">{summaryItems}</ul>}
    </div>
  )
}

const TooltipHeading = ({ heading, subtitle }: HeadingProps) => (
  <div className="Heading">
    <span>{heading}</span>
    <span>{subtitle}</span>
  </div>
)

export type TooltipData =
  | { tooltipContent: TooltipContent; tooltipProps?: never }
  | { tooltipContent?: never; tooltipProps: TooltipProps }

interface TooltipContent {
  items: TooltipItem[]
  date?: Date
  heading?: string
  summary?: SummaryItem[]
}

export interface TooltipItem {
  unit?: string
  name: string
  value: number
  color: string
  icon?: JSX.Element
  actionTypes?: {
    procurements: number
    expansions: number
    reltp: number
  }
}

export interface ChartTooltipProps {
  subHeading?: string
  x: number
  y: number
  data: TooltipData
  dateFormat?: DateFormat
  showDecimals?: boolean
  previousFY?: string
  graphName?: string
  withDynamicFormatting?: boolean
}

export const formatDateForTooltip = (date: Date, dateFormat: DateFormat, lang: Language) => {
  const locale = datefnsLocale(lang)
  switch (dateFormat) {
    case 'week':
      return `WEEK ${format(date, 'I', { locale })}`
    case 'monthWithYear':
      return format(date, 'MMM yyyy', { locale })
    case 'monthWithYearFy':
      return format(addYears(date, 1), 'MMM yyyy', { locale })
    case 'fy':
      return format(addYears(date, 1), "'FY'yy", { locale })
    case 'month':
      return format(date, 'MMMM', { locale })
    case 'monthTwoFYs':
      return format(date, "MMMM 'FY'yy", { locale })
    default:
      return ''
  }
}

export const formatNumberValue = (value: number, isDynamic: boolean, unit: string) => {
  if (!isDynamic && unit === '%') return formatRelativeNumber(value)

  if (isDynamic && Math.abs(value) < 100) return Math.round(value * 100) / 100

  return formatAbsoluteNumber(value)
}

export const ChartTooltip: React.FC<ChartTooltipProps> = ({
  subHeading,
  x,
  y,
  data,
  dateFormat,
  previousFY,
  graphName,
  showDecimals,
  withDynamicFormatting = false
}) => {
  const lang = React.useContext(LanguageContext)
  const xPlacement = x < window.innerWidth / 2 ? x + 60 : x - 280

  const heading = data.tooltipContent?.heading
    ? data.tooltipContent.heading
    : data.tooltipContent?.date && dateFormat
    ? formatDateForTooltip(data.tooltipContent.date, dateFormat, lang)
    : data.tooltipProps?.heading

  const sub = data.tooltipProps ? data.tooltipProps.subtitle : subHeading
  const body = data.tooltipContent
    ? [
        data.tooltipContent.items.map(i => {
          const value = showDecimals
            ? formatRelativeNumber(i.value)
            : formatNumberValue(i.value, withDynamicFormatting, i.unit || '')

          return {
            title: i.name === 'Planned activities' ? 'Total actions' : i.name,
            value: !i.value || isNaN(i.value) ? 'N/A' : value,
            color: i.color && i.color.length === 0 ? undefined : i.color,
            icon: i.icon ? i.icon : undefined,
            actionTypes: i.actionTypes
          }
        })
      ]
    : data.tooltipProps.body

  const summary = data.tooltipContent ? data.tooltipContent.summary : data.tooltipProps.summary

  if (Array.isArray(body) && (body as TooltipSectionItem[][])[0].length === 0) return null

  // Tooltip is rendered in a portal to ensure it is displayed on top of everything. Just setting
  // a z-index is not enough, if any of the parent elements creates a new stacking context.
  return (
    <PortalWrapper>
      <div
        style={{
          position: 'absolute',
          left: `${xPlacement}px`,
          top: `${y + window.scrollY - 50}px`,
          pointerEvents: 'none'
        }}
      >
        <div className="TooltipWrapper">
          <Tooltip
            heading={heading}
            subtitle={sub}
            body={body}
            summary={summary}
            previousFY={previousFY}
            graphName={graphName}
          />
        </div>
      </div>
    </PortalWrapper>
  )
}
