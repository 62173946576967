import React from 'react'
import { ChartContainer } from '../../../components/BaseGraphs/ChartContainer'
import { lineChart } from '../../../components/BaseGraphs/GraphUtil'
import { MainCard } from '../../../components/KPIPage'
import { formatCustomerTravelSeries, useCustomerTravelBenchmarkData } from './data/footprint'
import { CustomerTravelContext } from './CustomerTravelProvider'
import { useDynamicLabel } from './data/useDynamicLabel'
import { formatRelativeNumber } from '../../../components/Utils/format'

export const CustomerTravelMonthKPI = () => {
  const { selectedLocationBenchmark } = useCustomerTravelBenchmarkData()
  const { dates, footprint } = React.useContext(CustomerTravelContext)
  const personLabel = useDynamicLabel()

  const ytdSummary = selectedLocationBenchmark?.currentYtd ? (
    <span>
      {formatRelativeNumber(selectedLocationBenchmark.currentYtd)}{' '}
      <span className="Label">kg CO2e/{personLabel.single} YTD</span>
    </span>
  ) : undefined

  return (
    <MainCard title={`Climate Footprint per ${personLabel.single}`} subtitle={ytdSummary}>
      <div className="GraphContainer">
        <ChartContainer
          domain={dates}
          series={formatCustomerTravelSeries(footprint, dates)}
          generator={lineChart}
          dateFormat="month"
          lineChartConfiguration={{ focusStyle: 'none', startFromZero: false }}
          yAxisTitle={`kg CO2e/${personLabel.single}`}
          showDecimals
          withDynamicFormatting
        />
      </div>
    </MainCard>
  )
}
