export function formatPercentage(num: number) {
  if (!Number.isFinite(num)) {
    return 'N/A'
  }
  const value = num * 100
  return value.toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

export const formatChangePercentage = (change: number) => {
  if (Number.isNaN(change)) {
    return ''
  }
  return `${change > 0 ? '+' : ''}${formatPercentage(change)}%`
}

export function formatNumberToCompact(n: number) {
  if (n < 1000) {
    return n.toFixed(0)
  } else if (n < 1e6) {
    return (n / 1000).toFixed(0) + ' K'
  } else if (n < 1e9) {
    return (n / 1e6).toFixed(0) + ' M'
  } else {
    // Return values over a billion in a format where billions are space separated from millions, like this:
    // 1 234 567 890 -> "1 234M"
    const billions = Math.floor(n / 1e9)
    const millions = Math.floor((n % 1e9) / 1e6)
    const formattedMillions = ((n % 1e6) / 1e6).toFixed(0)

    return millions === 0
      ? `${billions}B`
      : `${billions} ${String(millions).padStart(3, '0')} ${formattedMillions.substring(2)}M`
  }
}

function formatNumberGeneralized(number: number, isRelative?: boolean) {
  if (typeof number !== 'number') {
    return 'Invalid input'
  }
  if (isRelative && Math.abs(number) <= 999) {
    return number.toFixed(1).replace('.0', '')
  }
  return number.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') // Adds space every 3 digits: e.g. '1 234 567'
}

export const formatAbsoluteNumber = (number: number) => formatNumberGeneralized(number, false)
export const formatRelativeNumber = (number: number) => formatNumberGeneralized(number, true)
export const formatValueAsTons = (number: number) => formatNumberGeneralized(number / 1000, false)

export const multiFormatNumber = (number: string | number | boolean | undefined | null) => {
  if (typeof number !== 'number') {
    return 'N/A'
  }
  if (number === 0) {
    return number
  }
  if (number < 100) {
    return formatRelativeNumber(number)
  }
  return formatAbsoluteNumber(number)
}
