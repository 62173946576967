import React from 'react'

import { useLocations } from '../../context'
import { ArrowIcon } from '../ArrowIcon'
import { getCluster, getLocationOrDefault } from '../Utils/utils'
import { ReactComponent as InformationIndicator } from '../../images/Icons/InformationIndicator.svg'
import { Tooltip } from '../../components/Tooltip'
import colours from '../../Colours.module.scss'
import { GridHr } from './GridHelpers'
import { KPIBenchmark } from '../../../api/src/common-types'
import { HeadingItem, BenchmarkingHeading, BenchmarkTableCell, useBenchmarkingHeaders } from '../../components/KPIPage'

export enum TableRowKey {
  PlanetFootprint = 'planetfootprint',
  FoodIngredients = 'foodingredients',
  PlanetRecycling = 'planetrecycling',
  PppSales = 'pppsales',
  Perception = 'perception',
  SocialImpact = 'socialimpact',
  ChangeMakers = 'changemakers',
  EnergyEfficiency = 'energyefficiency',
  WaterEfficiency = 'waterefficiency',
  CustomerDeliveries = 'customerdeliveries'
}

export const kpiNames: Record<TableRowKey, string> = {
  [TableRowKey.PlanetFootprint]: 'Climate Footprint - Own Operations',
  [TableRowKey.PlanetRecycling]: 'Recycling Rate',
  [TableRowKey.PppSales]: 'People+Planet Positive Sales',
  [TableRowKey.Perception]: 'Sustainability Mentions',
  [TableRowKey.SocialImpact]: 'Social Impact',
  [TableRowKey.ChangeMakers]: 'Change Makers',
  [TableRowKey.EnergyEfficiency]: 'Energy Efficiency',
  [TableRowKey.WaterEfficiency]: 'Water Efficiency',
  [TableRowKey.FoodIngredients]: 'Climate Footprint - Food Ingredients',
  [TableRowKey.CustomerDeliveries]: 'Climate Footprint - Zero Emission Deliveries'
}

interface BenchmarkTableRowProps<T extends KPIBenchmark> {
  rowKey: TableRowKey
  kpiUnits: string
  tooltip?: string
  headings: HeadingItem<T>[]
  mainBenchmark?: T
  childBenchmarks?: T[]
  expandedKey?: TableRowKey
  toggleExpanded(key: TableRowKey): void
}

export function BenchmarkTableRow<T extends KPIBenchmark>({
  rowKey,
  kpiUnits,
  tooltip,
  headings: rawHeaders = [],
  mainBenchmark,
  childBenchmarks = [],
  expandedKey,
  toggleExpanded
}: BenchmarkTableRowProps<T>) {
  const { clusters, currentLocation } = useLocations()
  const locationId = getLocationOrDefault()
  const onToggleExpanded = () => toggleExpanded(rowKey)
  const isExpanded = expandedKey === rowKey
  const headers = rawHeaders.filter(h => h.length > 0)
  const [headerKeys, updateHeaderKey] = useBenchmarkingHeaders(headers)
  const locationLabel = !currentLocation.isCluster
    ? currentLocation.location.label
    : getCluster(clusters, locationId)?.clusterName
  const extraColumns = Array(6 - headers.length)
    .fill(null)
    .map((_, i) => <div key={`extra-${i}`} />)

  return (
    <>
      {childBenchmarks.length > 0 ? (
        <button className="ExpandButton" onClick={onToggleExpanded}>
          <ArrowIcon angle={isExpanded ? 0 : 180} color={colours.offWhite1} />
        </button>
      ) : (
        <div />
      )}
      <div className={`KpiGroup ${rowKey}`}>
        <h6>
          {kpiNames[rowKey]}
          {tooltip && (
            <Tooltip tooltipText={tooltip}>
              <InformationIndicator width={16} height={16} />
            </Tooltip>
          )}
        </h6>
        <div className="KpiUnits">{kpiUnits}</div>
      </div>
      <div className="TopLevelSiteCountry">{locationLabel}</div>

      {extraColumns}
      {headers.map((header, index) => (
        <div key={index}>
          <BenchmarkingHeading
            className="TitleStyleSnapshot justify-end"
            item={header}
            selectedKey={headerKeys[index]}
            onValueChanged={val => updateHeaderKey(val, index)}
          >
            <BenchmarkTableCell
              header={header}
              selectedKey={headerKeys[index]}
              benchmark={mainBenchmark}
              locationId={locationId}
            />
          </BenchmarkingHeading>
        </div>
      ))}

      {isExpanded &&
        childBenchmarks.map((benchmark, i) => (
          <React.Fragment key={`${benchmark.id}-row`}>
            <GridHr className="Site" isHead={i === 0} />
            <div />
            <div />
            <div className="Site">{benchmark.label}</div>
            {extraColumns}
            {headers.map((header, index) => (
              <BenchmarkTableCell
                key={`${benchmark.id}-${index}`}
                header={header}
                selectedKey={headerKeys[index]}
                benchmark={benchmark}
                locationId={locationId}
              />
            ))}
          </React.Fragment>
        ))}
    </>
  )
}
