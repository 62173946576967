import React from 'react'
import colours from '../Colours.module.scss'

interface ArrowIconProps {
  angle: number
  color: string
  width?: number
  height?: number
}

export const ArrowIcon: React.FC<ArrowIconProps> = ({ angle, color, width = 16, height = 16 }) => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g transform={`rotate(${angle}, 10, 10)`}>
      <circle cx="10" cy="10" r="10" transform="rotate(-90 10 10)" fill={color} />
      <path
        d="M10.0004 8.69133L7.5223 11.5378C7.428 11.6203 7.30067 11.6667 7.16798 11.6667C7.03528 11.6667 6.90795 11.6203 6.81366 11.5378C6.7671 11.4972 6.73013 11.4489 6.7049 11.3956C6.67966 11.3423 6.66667 11.285 6.66667 11.2272C6.66667 11.1694 6.67966 11.1122 6.7049 11.0589C6.73013 11.0056 6.7671 10.9573 6.81366 10.9167L9.64532 7.62871C9.73982 7.54624 9.86724 7.5 10 7.5C10.1328 7.5 10.2602 7.54624 10.3547 7.62871L13.1863 10.9167C13.2329 10.9573 13.2699 11.0056 13.2951 11.0589C13.3203 11.1122 13.3333 11.1694 13.3333 11.2272C13.3333 11.285 13.3203 11.3423 13.2951 11.3956C13.2699 11.4489 13.2329 11.4972 13.1863 11.5378C13.092 11.6203 12.9647 11.6667 12.832 11.6667C12.6993 11.6667 12.572 11.6203 12.4777 11.5378L10.0004 8.69133Z"
        fill="black"
      />
    </g>
  </svg>
)
ArrowIcon.displayName = 'ArrowIcon'

interface WithMenu {
  menuIsOpen?: boolean
}

interface Disableable {
  isDisabled?: boolean
}

export interface ArrowSelectDropdownIndicatorProps extends WithMenu, Disableable {}

export const ArrowSelectDropdownIndicator: React.FC<ArrowSelectDropdownIndicatorProps> = ({
  menuIsOpen,
  isDisabled
}) => {
  const rotation = menuIsOpen ? 0 : 180
  const color = isDisabled ? colours.grey1 : colours.yellow
  return <ArrowIcon angle={rotation} color={color} />
}
ArrowSelectDropdownIndicator.displayName = 'ArrowSelectDropdownIndicator'
