import { ApiTotalFootprint } from '../../../../../api/src/common-types'
import { zeroWasteTypes } from '../../../../components/ZeroWasteGraph'

export const calculateTotalNonZeroWaste = (waste: ApiTotalFootprint[]) => {
  return waste.filter(w => !zeroWasteTypes.includes(w.treatmentTypeId)).reduce((acc, w) => acc + w.quantity, 0) / 1000
}

export const calculateBenchmarkingRecyclingRate = (
  ytdData: ApiTotalFootprint[],
  filterCb?: (w: ApiTotalFootprint) => boolean
) => {
  const ytdDataForLocation = filterCb ? ytdData.filter(filterCb) : ytdData
  const totalytd = ytdDataForLocation.reduce((acc, w) => acc + w.quantity, 0) / 1000
  const nonZeroytd = calculateTotalNonZeroWaste(ytdDataForLocation)
  const result = ((totalytd - nonZeroytd) / totalytd) * 100

  return isNaN(result) ? totalytd : result
}
